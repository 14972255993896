import React from 'react';
import { useStore } from '../stores/store';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import PageContainer from '../components/core/PageContainer';

const Home = observer(() => {
    const { authStore } = useStore();
    const { token } = authStore;

    const navigate = useNavigate();

    return (
        <PageContainer title='TimeIo | Home' className="">
            <div className="max-w-4xl mx-auto px-10 md:px-0 flex flex-col items-center text-center">
                <h1 className="font-bold p-0 text-white font-sans text-7xl md:text-8xl lg:text-9xl">TimeIo</h1>
                <div className="flex flex-col items-center mt-6">
                    <h4 className="text-primary font-bold text-xl py-4">Save time now.</h4>
                    {token ? (
                        <button className="font-sans bg-blue-600 hover:bg-blue-500 transition-colors text-white font-semibold mt-3 py-2 px-4 border rounded shadow" onClick={() => navigate('/app')}>Go To App</button> 
                    ) : (
                        <button className="font-sans bg-blue-600 hover:bg-blue-500 transition-colors text-white font-semibold mt-3 py-2 px-4 border rounded shadow" onClick={() => navigate('/login')}>Please login</button>
                    )}
                </div>
            </div>
        </PageContainer>
    );
});

export default Home;
