import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores/store';

const NavBar = observer(() => {
    const { authStore } = useStore();
    const { token } = authStore;

    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !(menuRef.current as HTMLElement).contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav className="fixed top-0 left-0 w-full z-50 bg-gradient-to-r from-purple-700 to-red-700 bg-opacity-75 p-6" ref={menuRef}>
            <div className="flex items-center justify-between flex-wrap">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <h3 className="font-semibold text-3xl md:text-4xl lg:text-5xl tracking-tight">TimeIo</h3>
                </div>
                <div className="block lg:hidden">
                    <button onClick={() => setIsOpen(!isOpen)} className="flex items-center px-3 py-2 border rounded text-white hover:text-gray-300 hover:border-gray-300">
                        <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
                    </button>
                </div>
                <div className={`w-full lg:flex lg:items-center lg:w-auto transition-all duration-300 ease-in-out overflow-hidden ${isOpen ? 'max-h-96' : 'max-h-0'} lg:max-h-full`}>
                    <div className="text-sm lg:flex-grow">
                        <Link to="/" onClick={() => setIsOpen(false)} className='block lg:inline-block text-white hover:text-gray-300 mr-4 text-xl py-5 lg:py-0 lg:px-5'>Home</Link>
                        <Link to="/app" onClick={() => setIsOpen(false)} className='block lg:inline-block text-white hover:text-gray-300 mr-4 text-xl py-5 lg:py-0 lg:px-5'>App</Link>
                        { token ? <Link to="/user" onClick={() => setIsOpen(false)} className='block lg:inline-block text-white hover:text-gray-300 mr-4 text-xl py-5 lg:py-0 lg:px-5'>Profile</Link>
                        : <Link to="/login" onClick={() => setIsOpen(false)} className='block lg:inline-block text-white hover:text-gray-300 mr-4 text-xl py-5 lg:py-0 lg:px-5'>Login</Link> }
                    </div>
                </div>
            </div>
        </nav>
    );
});

export default NavBar;
