import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useStore } from '../stores/store';
import { Formik } from 'formik';
import { UserProfile } from '../models/UserProfile/UserProfile';
import { UpdateUserProfileRequestDto } from '../models/UserProfile/UpdateUserProfileRequestDto';
import { FaCircleInfo, FaArrowRightToBracket } from "react-icons/fa6";
import PaperContainer from '../components/PaperContainer';
import PageContainer from '../components/core/PageContainer';
import * as Yup from 'yup';

const User = observer(() => {
    const { userProfileStore, authStore } = useStore();
    const { 
        getUserProfile, 
        userProfile, 
        loadingUserProfile, 
        savingUserProfile, 
        updateUserProfile 
    } = userProfileStore;
    const { logout } = authStore;

    const formVals = userProfile ?? {
        id: '',
        baseHourlyRate: 6,
        userId: '',
        customersEnabled : false,
        customers: []
    }

    const mapUserProfileToRequestDto = (userProfile: UserProfile): UpdateUserProfileRequestDto => {
        return {
            baseHourlyRate: userProfile.baseHourlyRate
        } 
    }

    useEffect(() => {
        if (!userProfile) {
            getUserProfile();
        }
    }, [updateUserProfile]);

    const validationSchema = Yup.object().shape({
        baseHourlyRate: Yup.number()
            .required('Base hourly rate is required')
            .min(0.01, 'Base hourly rate must be more than 0')
    });

    return (
        <PageContainer title='TimeIo | User'>
            {loadingUserProfile ? 
                <h2 className="text-white font-bold">Loading...</h2> : 
                <PaperContainer >
                    <Formik
                        initialValues={formVals}
                        onSubmit={(values) => updateUserProfile(mapUserProfileToRequestDto(values))}
                        enableReinitialize
                        validationSchema={validationSchema}>
                        {({handleSubmit, handleChange, values, errors, touched, handleBlur, dirty, isValid}) => (
                            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded p-6 border-none">
                                <h3 className='text-3xl lg:text-4xl font-bold pb-5 text-center'>User Profile</h3>
                                <div className='flex items-center mb-4 gap-3'>
                                    <FaCircleInfo className="mr-2 text-2xl" />
                                    <p>Update your user profile to customize your experience</p>
                                </div>
                                { errors.baseHourlyRate && touched && <div className="text-red-500">{errors.baseHourlyRate}</div>}
                                <div className="mb-5">
                                    <h2 className=" text-l font-bold">Personal</h2>
                                    <hr className="mb-2" />
                                    <div className="mb-4 px-2">
                                        <label htmlFor="baseHourlyRate" className="block text-gray-700 text-sm font-bold mb-2">Base Hourly Rate</label>
                                        <input 
                                            type="number" 
                                            name="baseHourlyRate" 
                                            id="baseHourlyRate" 
                                            value={values.baseHourlyRate} 
                                            onBlur={handleBlur}
                                            onChange={handleChange} 
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="flex justify-between">
                                        <h2 className="mb-1 text-l font-bold">Customers</h2>
                                        <input 
                                            type="checkbox" 
                                            name="customersEnabled" 
                                            id="customersEnabled"
                                            disabled={true}
                                            className="mr-2" />
                                    </div>
                                    <hr className="mb-2" />
                                    <div className="mb-4 px-2">
                                        <p className="text-center">Customer profiles and settings are coming soon!</p>
                                    </div>
                                </div>
                             
                             
                                <div className="flex flex-col items-center">
                                    <input 
                                        className="bg-green-500 hover:bg-green-700 transition-colors w-36 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-2" 
                                        type="submit" 
                                        value={savingUserProfile ? "Saving..." : "Save"} 
                                        disabled={savingUserProfile || !dirty || !isValid}
                                    />
                                    <button 
                                        className="bg-blue-500 hover:bg-blue-700 transition-colors w-36 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center justify-center"
                                        type="button" 
                                        onClick={logout}
                                    >
                                        <FaArrowRightToBracket className="mr-1" /> Logout
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </PaperContainer>
            }
        </PageContainer>
    );
});

export default User;
