import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import { useStore } from './stores/store';
import Application from './pages/Application';
import { observer } from 'mobx-react-lite';
import NavBar from './components/NavBar';
import User from './pages/User';
import Register from './pages/Register';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Footer from './components/Footer';

const App = observer(() => {
  const { authStore } = useStore();
  const { token } = authStore;

  const requireAuth = (element: React.ReactElement) => {
    return token ? element : <Navigate to="/login" />;
  };


  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/app" element={requireAuth(<Application />)} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/user" element={ requireAuth(<User />) } />
      </Routes>
      <Footer />
      <ToastContainer
        position= "bottom-right"
        autoClose= {5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true} />
    </div>
  );
});

export default App;
