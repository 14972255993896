import { createContext, useContext } from "react";
import AuthStore from "./authStore";
import ActivityStore from "./activityStore";
import UserProfileStore from "./userProfileStore";

interface Store {
    activityStore : ActivityStore
    authStore : AuthStore
    userProfileStore : UserProfileStore
}

export const store: Store = {
    activityStore : new ActivityStore(),
    authStore : new AuthStore(),
    userProfileStore : new UserProfileStore()
}

export const StoreContext = createContext(store);

export function useStore(){
    return useContext(StoreContext);
}