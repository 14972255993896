import { makeAutoObservable, runInAction } from "mobx";
import { Activity } from "../models/Activities/Activity";
import { EditActivity } from "../models/Activities/EditActivity";
import { EditFormMode } from "../enums/EditFormMode";
import agent from "../api/agent";
import { Totals } from "../models/Activities/Totals";
import { toast } from "react-toastify";

export default class ActivityStore {
    fetchingActivities : boolean = false;
    activities : Activity[] = [];
    totals : Totals | undefined = undefined;
    chosenDateForActivities : Date = new Date();
    chosenDateHasChanged : boolean = false; 
    selectedActivity : Activity | undefined = undefined;

    editActivityFormMode : EditFormMode = EditFormMode.Create;
    selectedActivityToEdit: EditActivity | undefined = undefined;
    formSubmissionIsLoading: boolean = false;

    editActivityFormIsVisible : boolean = false;
    activitySummaryIsVisible: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    getActivities = async () => {
        if (this.fetchingActivities) {
            return;
        }

        const monthYear = this.chosenDateForActivities?.toISOString() ?? new Date().toISOString();

        this.setFetchingActivities(true);
        try {
            const response = await agent.activities.list(monthYear);
            if (response !== undefined) {
                this.setActivities(response.data.activities);
                this.setTotals(response.data.totals);
            }
            else {
                this.setActivities([]);
                this.setTotals({totalTime: '0.00:00', totalCharged: 0})
            }
        }
        catch (error){
            this.setActivities([]);
            this.setTotals({totalTime: '0.00:00', totalCharged: 0})
        }
        finally {
            if (this.chosenDateHasChanged) {
                this.setChosenDateHasChanged(false);
            }
            this.setFetchingActivities(false);
        }
    }

    handleCreateOrEditActivity = async (activity : EditActivity) => {
        if (this.editActivityFormMode === EditFormMode.Create) {
            await this.createActivity(activity);
        }
        else {
            await this.editActivity(activity);
        }
    }

    createActivity = async (activity : EditActivity) => {
        try {
            this.setFormSubmissionIsLoading(true);
            const response = await agent.activities.create(activity);
            if (response && response.data.activity) {
                runInAction(() => {
                    if (!this.chosenDateForActivities || new Date(this.chosenDateForActivities).getMonth() === new Date(response.data.activity.startDateTime).getMonth()){
                        this.insertActivityByStartDateTime(response.data.activity);
                    }
                    
                    if (this.totals !== undefined) {
                        const newTime = ammendTotalTime(this.totals.totalTime, getTotalTimeInMinutes(response.data.activity.totalTime));
                        this.setTotals({
                            totalCharged: this.totals.totalCharged + response.data.activity.amountCharged,
                            totalTime: newTime
                        });
                    }
                    
                    this.setEditActivityFormVisible(false);
                    toast.success("Activity created successfully.");
                });
            }
        }
        catch (error) {
            toast.error("Error occurred while creating the activity. Please try again.");
        }
        finally {
            this.setFormSubmissionIsLoading(false);
        }
    }

    editActivity = async (activity : EditActivity) => {
        try {
            this.setFormSubmissionIsLoading(true);
            const response = await agent.activities.edit(activity);
            if (response !== undefined) {
                runInAction(() => {
                    this.activities = [...this.activities.filter((ac) => ac.id !== response.data.activity.id), response.data.activity].sort((a, b) => a.startDateTime > b.startDateTime ? 1 : -1);
                    this.setEditActivityFormVisible(false);
                    toast.success("Activity edited successfully.");
                });
            }
        }
        catch (error) {
            toast.error("An error occurred while editing the activity. Please try again.");
        }
        finally {
            this.setFormSubmissionIsLoading(false);
        }
    }

    deleteActivity = async ( id : string) => {
        const activity = this.activities.find(x => x.id === id);
        if (!activity) {
            toast.error("Activity not found. Could not process deletion.");
        }

        const sanitisedDateTime = activity!.startDateTime.toString().split('+')[0];

        try {
            const response = await agent.activities.delete(id, sanitisedDateTime);
            if (response !== undefined) {
                runInAction(() => {
                    if (this.totals !== undefined) {
                        const newTime = ammendTotalTime(this.totals.totalTime, -getTotalTimeInMinutes(activity!.totalTime));
                        this.setTotals({
                            totalCharged: this.totals.totalCharged - activity!.amountCharged,
                            totalTime: newTime
                        });
                    }
                    this.activities = [...this.activities.filter((ac) => ac.id !== id)];
                    toast.success("Activity deleted successfully.");
                });
            }
        }
        catch (error) {
            toast.error("An error occurred while deleting the activity. Please try again.");
        }
    }

    exportActivities = async () => {
        try {
            toast.warn('Exporting activities...');
            const response = await agent.exportItems.activities(this.chosenDateForActivities.toISOString());
            if (response.data) {
                var hiddenElement = document.createElement('a');
                hiddenElement.href = `data:${response.headers['content-type']};base64,${response.data}`;
                hiddenElement.target = '_blank';

                if (response.headers['content-disposition']) {
                    hiddenElement.download = response.headers['content-disposition'].split('=')[1].replace(/"/g, '');
                } else {
                    hiddenElement.download = 'activities-export.xlsx';
                }
                hiddenElement.click();
                hiddenElement.remove();
            } else {
                toast.error('No data to export.');
            }
        } catch (error) {
            toast.error('Something went wrong while exporting the activities.');
        }
    }

    setSelectedActivityToEdit = (activityId : string | null) => {
        if (activityId === null) {
            this.selectedActivityToEdit = undefined;
            return;
        }

        const activity = this.activities.find((a) => a.id === activityId);
        if (activity !== undefined) {
            this.selectedActivityToEdit = {
                id: activity.id,
                dateTime: activity.startDateTime.toString().split('T')[0],
                startDateTime: activity.startDateTime.toString().split('T')[1].substring(0, 5),
                finishDateTime: activity.finishDateTime.toString().split('T')[1].substring(0, 5),
                hourlyRate: activity.hourlyRate,
                description: activity.description
            }
        }
    }

    setChosenDateHasChanged = (value : boolean) => {
        this.chosenDateHasChanged = value;
    }

    setFormSubmissionIsLoading = (value : boolean) => {
        this.formSubmissionIsLoading = value;
    }

    setChosenDateForActivities = (date : Date) => {
        this.setChosenDateHasChanged(true);
        this.chosenDateForActivities = date;
    }

    setEditActivityFormMode = (mode : EditFormMode) => {
        this.editActivityFormMode = mode;
    }

    setEditActivityFormVisible = (visible : boolean, mode : EditFormMode | null = null) => {
        if (mode !== null){
            this.editActivityFormMode = mode;
        }
        
        this.editActivityFormIsVisible = visible;
    }

    setActivities = (activities : Activity[]) => {
        this.activities = activities;
    }

    setTotals = (totals : Totals) => { 
        const timeString = getTimeFormatted(totals.totalTime);
        totals.totalTime = timeString;
        this.totals = totals;
    }

    setFetchingActivities = (value : boolean) => {
        this.fetchingActivities = value;
    }

    setActivitySummaryIsVisible = (shouldShow : boolean, activityId? : string) => {
        if (!shouldShow) {
            this.activitySummaryIsVisible = false;
            return;
        }

        this.selectedActivity = this.activities.find(x => x.id === activityId);

        if (!this.selectedActivity) {
            return;
        }

        this.activitySummaryIsVisible = shouldShow;
    }

    insertActivityByStartDateTime = (activity : Activity) => {
        if (!this.activities || this.activities.length === 0) {
            this.activities = [activity];
            return;
        }

        let i  = 0;
        while (i < this.activities.length && this.activities[i].startDateTime < activity.startDateTime) {
            i++;
        }
        this.activities.splice(i, 0, activity);
    }
}

function getTimeFormatted(timeString : string) {
    const timeArray = timeString.split('.');
    let totalHours = 0;
    if (timeArray.length === 2) {
        const days = parseInt(timeArray[0]);
        totalHours = days * 24;
        timeArray.shift();
    }

    const minutesHours = timeArray[0].split(':');
    if (minutesHours.length === 1) {
        return "";
    }

    return `${totalHours + parseInt(minutesHours[0])}:${minutesHours[1]}`;
}

function getTotalTimeInMinutes(timeString : string) : number {
    // Expecting the time to be in hh:mm format.
    const timeArray = timeString.split(':');
    if (timeArray.length < 2) {
        return 0;
    }

    return parseInt(timeArray[0]) * 60 + parseInt(timeArray[1]);
}

function ammendTotalTime(totalTimeString : string, minutes : number) {
    // minutes could be positive or negative.
    let currentTotalTimeMinutes = getTotalTimeInMinutes(totalTimeString);
    currentTotalTimeMinutes += minutes;

    const hours = Math.floor(currentTotalTimeMinutes / 60).toString().padStart(2, '0');
    const minutesLeft = (currentTotalTimeMinutes % 60).toString().padStart(2, '0');
    return `${hours}:${minutesLeft}`;
}