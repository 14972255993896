import { Formik } from 'formik';
import React from 'react';
import Question from '../components/Question';
import PaperContainer from '../components/PaperContainer';
import { useStore } from '../stores/store';
import { useNavigate } from 'react-router-dom';
import PageContainer from '../components/core/PageContainer';
import * as Yup from 'yup';

const Register = () => {
    const { authStore } = useStore();
    const { registerFormIsLoading, register } = authStore;
    let navigate = useNavigate();

    interface RegisterFormValues {
        email: string;
        password: string;
        passwordConfirmed: string;
        registrationKey: string;
    }

    const formVals : RegisterFormValues = {
        email : "",
        password: "",
        passwordConfirmed: "",
        registrationKey: ""
    }

    const handleSubmit = (values: RegisterFormValues) => {
        if (values.password !== values.passwordConfirmed) {
            alert('Passwords do not match');
            return;
        }
        register({
            email: values.email, 
            password: values.password, 
            registrationKey: values.registrationKey});
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('Required')
            .max(200, 'Email must be less than 100 characters'),
        password: Yup.string()
            .required('Required')
            .max(50, 'Password must be less than 50 characters')
            .min(8, 'Password must be at least 8 characters'),
        passwordConfirmed: Yup.string()
            .required('Password must be confirmed')
            .max(50, 'Password must be less than 50 characters')
            .min(8, 'Password must be at least 8 characters')
            .test(
                'passwords-match', 
                'Passwords must match', 
                function(value) {
                    return this.parent.password === value;
                }),
        registrationKey: Yup.string()
            .required('Registration key is required')
    });

    return (
        <PageContainer title='TimeIo | Register'>
            {registerFormIsLoading ? <p className="font-bold text-white">Registering...</p> :
            <Formik
                initialValues={formVals}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize
                validationSchema={validationSchema}
                >
                    {({handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, dirty}) => (
                    <PaperContainer>
                        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded p-6 border-none">
                            <h3 className='text-3xl lg:text-4xl font-bold pb-5 text-center'>Register</h3>
                            <Question 
                                type='text' 
                                title='Email' 
                                value={values.email} 
                                error={errors.email}
                                touched={touched.email}
                                handleChangeEvent={handleChange} 
                                handleBlurEvent={handleBlur} 
                                id='email' />

                            <Question 
                                type='password' 
                                title='Password' 
                                value={values.password} 
                                error={errors.password}
                                touched={touched.password}
                                handleChangeEvent={handleChange} 
                                handleBlurEvent={handleBlur} 
                                id='password' />

                            <Question 
                                type='password' 
                                title='Confirm Password' 
                                value={values.passwordConfirmed} 
                                error={errors.passwordConfirmed}
                                touched={touched.passwordConfirmed}
                                handleChangeEvent={handleChange} 
                                handleBlurEvent={handleBlur} 
                                id='passwordConfirmed' />

                            <Question 
                                type='text' 
                                title='Registration Key' 
                                value={values.registrationKey} 
                                error={errors.registrationKey}
                                touched={touched.registrationKey}
                                handleChangeEvent={handleChange} 
                                handleBlurEvent={handleBlur} 
                                id='registrationKey' />

                            <div className="flex flex-col justify-center items-center">
                                <input 
                                    type="submit" 
                                    value={registerFormIsLoading ? "Registering..." : "Register" }
                                    className="bg-green-500 hover:bg-green-700 transition-colors w-36 text-white mt-[10px] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                                    disabled={registerFormIsLoading || !isValid || !dirty}
                                />
                                <button 
                                    type="button" 
                                    className="bg-blue-500 hover:bg-blue-700 transition-colors w-36 text-white mt-[10px] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                    onClick={() => navigate('/login')}
                                >
                                    Cancel
                                </button> 
                            </div>
                        </form>
                    </PaperContainer>
                    )}
            </Formik>}
        </PageContainer>
    )
}

export default Register;
