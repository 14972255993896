import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { useStore } from '../../stores/store';
import { EditFormMode } from '../../enums/EditFormMode';
import ActivityTableRow from './ActivityTableRow';

const ActivityTable = observer(() => {
    const { activityStore } = useStore();
    const { 
        activities, 
        totals,
        getActivities, 
        fetchingActivities, 
        chosenDateForActivities,
        chosenDateHasChanged,
        setEditActivityFormVisible,
        setSelectedActivityToEdit,
        deleteActivity } = activityStore;

    useEffect(() => {
        if ((fetchingActivities === false && activities.length === 0) || chosenDateHasChanged) {
            getActivities();
        }
    }, [chosenDateForActivities]);

    const openEditForm = (id : string) => {
        setSelectedActivityToEdit(id);
        setEditActivityFormVisible(true, EditFormMode.Update);
    }
    
    const mapActivities = () => {
        if (!activities || activities.length === 0) {
            return (<TableRow>
                <TableCell colSpan={6} align='center'>No time logged this month!</TableCell>
            </TableRow>);
        }

        return activities.map((activity) => (
            <ActivityTableRow key={activity.id} activity={activity} deleteActivity={deleteActivity} openEditForm={openEditForm} /> )
        )}

    const renderTableBody = () => {
        if (fetchingActivities) {
            return (
            <TableRow>
                <TableCell colSpan={6} align='center'>Loading...</TableCell>
            </TableRow>);
        } else {
            return mapActivities();
        }
    }

    const renderTotals = (): React.ReactNode => {
        if (!totals) return <></>;
        return (
            <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell><b>{totals?.totalTime}</b></TableCell>
                <TableCell><b>£{totals?.totalCharged.toFixed(2)}</b></TableCell>
                <TableCell></TableCell>
            </TableRow>
        )
    }

    return (
        <TableContainer component={Paper} className="max-h-[90vh]">
            <Table size='medium'>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight:'bold'}}>Date</TableCell>
                        <TableCell sx={{fontWeight:'bold'}}>Start</TableCell>
                        <TableCell sx={{fontWeight:'bold'}}>Finish</TableCell>
                        <TableCell sx={{fontWeight:'bold'}}>Total Time</TableCell>
                        <TableCell sx={{fontWeight:'bold'}}>Amount Charged</TableCell>
                        <TableCell sx={{fontWeight:'bold'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderTableBody()}
                    {renderTotals()}
                </TableBody>
            </Table>
            {window.innerWidth <= 768 ? <p className="text-center">Scroll for actions &#x2192;</p> : <></>}
        </TableContainer>
    )
});

export default ActivityTable;
