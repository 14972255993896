import React from 'react'


interface Props {
    children: React.ReactNode
}

const PaperContainer  = ({ children }: Props) => {
    return (
        <div className="p-5 flex-col justify-center">
            {children}
        </div>
    )
}

export default PaperContainer;