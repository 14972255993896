import React from 'react';
import ActivityTable from '../components/application/ActivityTable';
import ActivityTableActionBar from '../components/application/ActivityTableActionBar';
import EditActivitiyFormModal from '../components/application/EditActivityForm';
import PageContainer from '../components/core/PageContainer';
import ActivitySummary from '../components/application/ActivitySummary';

const Application = () => {
    return (
        <PageContainer title='TimeIo | My Log' flexMode='flex-col'>
            <ActivitySummary />
            <EditActivitiyFormModal />
            <ActivityTableActionBar />
            <ActivityTable />
        </PageContainer>
    )
}

export default Application;