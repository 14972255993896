import React from 'react'
import { observer } from 'mobx-react-lite';
import { FaCirclePlus, FaDownload } from "react-icons/fa6";
import { useStore } from '../../stores/store';
import { EditFormMode } from '../../enums/EditFormMode';

const ActivityTableActionBar = observer(() => {
    const { activityStore } = useStore();
    const { 
        setEditActivityFormVisible, 
        setChosenDateForActivities, 
        chosenDateForActivities,
        exportActivities } = activityStore;

    const handleChosenDateChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.value) {
            return;
        }

        const fieldDate = new Date(event.target.value);
        if (fieldDate === chosenDateForActivities) {
            return;
        }

        setChosenDateForActivities(fieldDate);
    }

    return (
       <div className="bg-slate-500 flex justify-center">
            <ul className="flex justify-end gap-4 w-[93%] py-2 items-center">
                <h3 className="font-bold text-nowrap">{chosenDateForActivities.toLocaleString('en-US', { month: 'long', year: 'numeric' })}</h3>
                <input type="date" className="w-[75%]" onBlur={handleChosenDateChange}/>
                <li className="text-2xl p-2 text-green-600" onClick={() => setEditActivityFormVisible(true, EditFormMode.Create)}><FaCirclePlus/></li>
                <li className="text-2xl p-2 text-white" onClick={() => exportActivities()}><FaDownload /></li>
            </ul>
       </div> 
    );
});

export default ActivityTableActionBar