import React from 'react';
import { Helmet } from 'react-helmet';

interface PageContainerProps {
    title: string;
    children: React.ReactNode;
    flexMode?: 'flex' | 'flex-col';
    className?: string;
}

const PageContainer = ({ title, children, flexMode, className } : PageContainerProps) => {
    return (
        <div className={`min-h-screen flex flex-col ${className}`}>
            <Helmet> 
                <title>{title}</title>
            </Helmet>
            <main className={`flex-grow pt-24 ${flexMode ?? 'flex'} items-center justify-center`}>
                {children}
            </main>
        </div>
    );
}

export default PageContainer;
