import { makeAutoObservable, reaction, runInAction } from "mobx";
import { LoginDTO } from "../models/Login/LoginDTO";
import agent from "../api/agent";
import { redirect } from "react-router-dom";
import { RegisterDto } from "../models/Login/RegisterDto";
import { toast } from "react-toastify";
import { RefreshDto } from "../models/Login/RefreshDto";
import UserProfileStore from "./userProfileStore";
import { store } from "./store";

export default class AuthStore {
    token : string | null = window.localStorage.getItem('token');
    refreshToken : string | null = window.localStorage.getItem('refreshToken');
    loginFormIsLoading : boolean = false;
    hasTriedRefresh : boolean = false;

    registerFormIsLoading : boolean = false;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.token,
            token => {
                if(token) {
                    window.localStorage.setItem('token', token);
                } else {
                    window.localStorage.removeItem('token');
                }
            }
        )
    }

    login = async (credentials : LoginDTO) => {
        this.setLoginFormIsLoading(true);
        try {
            if (credentials && credentials.email && credentials.password) {
                const response = await agent.authentication.login(credentials);
                if (response.data && response.data.IdToken && response.data.RefreshToken) {
                    this.setToken(response.data.IdToken);
                    this.setRefreshToken(response.data.RefreshToken);
                    
                    runInAction(async () => {
                        await store.userProfileStore.getUserProfile(false);
                    })
                }
            }
        }
        catch (error) {
            toast.error("An error occurred while logging in. Please try again.");
        }
        finally {
            this.setLoginFormIsLoading(false);
            this.setHasTriedRefresh(false);
        }
    }

    refresh = async () => {
        if (!this.refreshToken) {
            toast.error('Please log in again');
            this.logout();
            return;
        }
        const refreshRequest : RefreshDto = { refreshToken : this.refreshToken }; 
        try {
            const response = await agent.authentication.refresh(refreshRequest);
            if (response.data && response.data.IdToken) {
                this.setToken(response.data.IdToken);
                this.setRefreshToken(response.data.RefreshToken);
            }
        }
        catch (error) {
            toast.error('Please log in again');
            this.logout();
        }
        finally {
            this.setHasTriedRefresh(true);
        }
    }

    logout = () => {
        this.setToken(null);
        this.setHasTriedRefresh(false);
        redirect('/');
    }

    register = async (credentials : RegisterDto) => {
        this.setRegisterFormIsLoading(true);
        try {
            if (credentials && credentials.email && credentials.password && credentials.registrationKey) {
                const response = await agent.authentication.register(credentials);
                if (response) {
                    toast.success('Successfully registered. Please log in.');
                    redirect('/login');
                }
                else {
                    toast.error('Failed to register');
                }
            }
            else {
                toast.error("Credentials are invalid. Please check them and try again.")
            }
        }
        catch (error) {
            toast.error("An error occurred while registering. Please try again.");
        }
        finally {
            this.setRegisterFormIsLoading(false);
        }
    }

    setToken = (token: string | null) => {
        if (token) {
            window.localStorage.setItem('token', token);
        }
        this.token = token;
    }

    setRefreshToken (refreshToken: string | null) {
        if (refreshToken) {
            window.localStorage.setItem('refreshToken', refreshToken);
        }

        this.refreshToken = refreshToken;
    }
    
    setLoginFormIsLoading = (loading: boolean) => {
        this.loginFormIsLoading = loading;
    }

    setRegisterFormIsLoading = (loading : boolean) => {
        this.registerFormIsLoading = loading;
    }

    setHasTriedRefresh = (hasTried : boolean) => {
        this.hasTriedRefresh = hasTried;
    }
}