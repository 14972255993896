import { makeAutoObservable, reaction } from "mobx";
import agent from "../api/agent";
import { UserProfile } from "../models/UserProfile/UserProfile";
import { store } from "./store";
import { UpdateUserProfileRequestDto } from "../models/UserProfile/UpdateUserProfileRequestDto";
import { toast } from "react-toastify";

export default class UserProfileStore {
    readonly userProfileStorageString : string = "userProfile";
    userProfile : UserProfile | null = null;
    loadingUserProfile = false;
    
    savingUserProfile = false;

    constructor() {
        makeAutoObservable(this);

        this.userProfile = this.TryDeserializeUserProfile();

        reaction(
            () => this.userProfile,
            userProfile => {
                if(userProfile) {
                    window.localStorage.setItem(this.userProfileStorageString, JSON.stringify(userProfile));
                } else {
                    window.localStorage.removeItem(this.userProfileStorageString);
                }
            }
        )
    }

    getUserProfile = async (notifyUser : boolean = true) => {
        if (this.loadingUserProfile) {
            return;
        }

        this.setLoadingUserProfile(true);
        try {
            if (!store.authStore.token) {
                toast.warn('Please log in.');
                return;
            }
            
            try {
                const userProfile = window.localStorage.getItem(this.userProfileStorageString);
                const userProfileParsed = userProfile ? JSON.parse(userProfile) : null;

                if (userProfileParsed) {
                    this.userProfile = userProfileParsed;
                    this.setLoadingUserProfile(false);
                    return;
                }
            }
            catch (error) {
                if (notifyUser) {
                    toast.error("An error occurred while fetching the profile.");
                }
            };

            try {
                const response = await agent.userProfile.get();
                if (response) {
                    window.localStorage.setItem(this.userProfileStorageString, JSON.stringify(response.data));
                    this.userProfile = response.data;
                }
            }
            catch (error) {
                if (notifyUser) {
                    toast.error("Error occurred while fetching the profile.");
                }
            };
        }
        finally {
            this.setLoadingUserProfile(false);
        }
    }

    updateUserProfile = async (userProfile: UpdateUserProfileRequestDto) => {
        this.setSavingUserProfile(true);
        try {
            if (!store.authStore.token) {
                toast.warn('Please log in.');
                return;
            }
            
            try {
                const response = await agent.userProfile.update(userProfile);
                if (response && response.data) {
                    window.localStorage.setItem(this.userProfileStorageString, JSON.stringify(response));
                    this.setUserProfile(response.data);
                    toast.success('Profile updated');
                }
            }
            catch (error) {
                toast.error("An error occurred while updating the profile.");
            };
        }
        finally {
            this.setSavingUserProfile(false);
        }
    }

    TryDeserializeUserProfile = () : UserProfile | null => {
        const userProfile = window.localStorage.getItem(this.userProfileStorageString)
        if (userProfile) {
            return JSON.parse(userProfile);
        }
        else {
            return null;
        }
    }

    setSavingUserProfile = (saving: boolean) => {
        this.savingUserProfile = saving;
    }

    setLoadingUserProfile = (loading: boolean) => {
        this.loadingUserProfile = loading;
    }

    setUserProfile = (userProfile: UserProfile) => {
        this.userProfile = userProfile;
    }
}