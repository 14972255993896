
import { Formik } from 'formik';
import React from 'react'
import { LoginDTO } from '../models/Login/LoginDTO';
import { useStore } from '../stores/store';
import { Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import PaperContainer from '../components/PaperContainer';
import Question from '../components/Question';
import PageContainer from '../components/core/PageContainer';
import * as Yup from 'yup';

const Login = observer(() => {
    const { authStore } = useStore();
    const { login, token, loginFormIsLoading } = authStore;
    let navigate = useNavigate();

    const formVals : LoginDTO = {
        email : "",
        password: ""
    }

    const handleSubmit = (values: LoginDTO) => {
        login(values);
        return navigate("/app");
    }

    if (token) {
        return <Navigate to="/app" />;
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required')
            .max(100, 'Email must be less than 200 characters'),
        password: Yup.string()
            .required('Password is required')
            .max(50, 'Password must be less than 50 characters')
    });

    return (
        <PageContainer title='TimeIo | Login'>
            {loginFormIsLoading ? <p className="font-bold text-white">Logging in...</p> :
            <Formik
                initialValues={formVals}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize
                validationSchema={validationSchema}
                >
                    {({handleSubmit, handleChange, handleBlur, values, errors, touched, isValid, dirty}) => (
                    <PaperContainer>
                        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded p-6 border-none">
                            <h3 className='text-3xl lg:text-4xl font-bold pb-5 text-center'>Login</h3>
                            <Question 
                                type='text' 
                                title='Email' 
                                value={values.email} 
                                error={errors.email}
                                touched={touched.email}
                                handleChangeEvent={handleChange} 
                                handleBlurEvent={handleBlur} 
                                id='email' />

                            <Question 
                                type='password' 
                                title='Password' 
                                value={values.password} 
                                error={errors.password}
                                touched={touched.password}
                                handleChangeEvent={handleChange} 
                                handleBlurEvent={handleBlur} 
                                id='password' />
                            <div className="flex flex-col items-center justify-center">
                                <input
                                    type="submit" 
                                    value="Login" 
                                    className="bg-green-500 hover:bg-green-700 transition-colors w-36 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                                    disabled={loginFormIsLoading || !isValid || !dirty}/>
                                <button 
                                    className="my-2 bg-blue-500 hover:bg-blue-700 transition-colors w-36 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" 
                                    onClick={() => navigate('/register')}>Register</button>
                            </div>
                        </form>
                    </PaperContainer>
                    )}
            </Formik>}
        </PageContainer>
    );
});

export default Login;