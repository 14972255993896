import React from 'react'

interface Props {
    children: React.ReactNode
}


const Modal = ({ children }: Props) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-4">
                {children}
            </div>
        </div>
    )
}

export default Modal;