import React from 'react'

interface Props {
    id: string;
    title: string;
    type: string;
    value: string;
    handleChangeEvent : (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleBlurEvent : (e: React.FocusEvent<HTMLInputElement>) => void;
    placeHolder?: string;
    maxLength?: number;
    error?: string;
    touched?: boolean;
}

const Question = ({id, title, type, handleChangeEvent, handleBlurEvent, value, placeHolder, maxLength, error, touched} : Props) => {
    return (
        <div className="mb-4 mx-4">
            { error && touched && <div className="text-red-500">{error}</div>}
            <label htmlFor={id} className="block text-gray-700 text-sm font-bold mb-2">{title}</label>
            <input 
                type={type} 
                name={id} 
                id={id} 
                value={value}
                maxLength={maxLength}
                placeholder={placeHolder}
                onChange={handleChangeEvent} 
                onBlur={handleBlurEvent}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"/>
        </div>
    )
}

export default Question
