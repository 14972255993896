import React from 'react';
import Modal from '../Modal';
import { useStore } from '../../stores/store';
import { observer } from 'mobx-react-lite';
import { FaPencil } from 'react-icons/fa6';
import { EditFormMode } from '../../enums/EditFormMode';

const ActivitySummary = observer(() => {
    const { activityStore } = useStore();
    const { activitySummaryIsVisible, selectedActivity, setActivitySummaryIsVisible, setSelectedActivityToEdit, setEditActivityFormVisible } = activityStore;

    const openEditForm = (id : string) => {
        setActivitySummaryIsVisible(false);
        setSelectedActivityToEdit(id);
        setEditActivityFormVisible(true, EditFormMode.Update);
    }

    if ( !activitySummaryIsVisible || !selectedActivity  ) {
        return <></>
    }

    const formatDate = (dateString : Date) => {
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };
    
    const formatTime = (dateString : Date) => {
        return dateString.toString().split('T')[1].split('+')[0].slice(0, 5);
    };

    const formatCurrency = (amount : number) => {
        return "£" + amount.toFixed(2);
    };

    return (
        <Modal>
            <div className='relative'>
                <h2 className="text-2xl font-bold mb-4 text-center">Activity Summary</h2>
                <button 
                            className=' transition-colors text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline absolute top-0 right-0' 
                            onClick={() => openEditForm(selectedActivity.id)}
                        >
                            <FaPencil/>
                </button>
                <div>
                    <input type="hidden" value={selectedActivity.id} />
                    
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Date</label>
                        <p className="bg-gray-100 p-2 rounded">{formatDate(selectedActivity.startDateTime)}</p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Start</label>
                        <p className="bg-gray-100 p-2 rounded">{formatTime(selectedActivity.startDateTime)}</p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Finish</label>
                        <p className="bg-gray-100 p-2 rounded">{formatTime(selectedActivity.finishDateTime)}</p>
                    </div>

                    { selectedActivity.description &&
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Description</label>
                            <p className="bg-gray-100 p-2 rounded">{selectedActivity.description}</p>
                        </div>
                    }

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Hourly Rate</label>
                        <p className="bg-gray-100 p-2 rounded">{formatCurrency(selectedActivity.hourlyRate)}</p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Total Time</label>
                        <p className="bg-gray-100 p-2 rounded">{selectedActivity.totalTime.slice(0, selectedActivity.totalTime.length - 3)}</p>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Amount Charged</label>
                        <p className="bg-gray-100 p-2 rounded">{formatCurrency(selectedActivity.amountCharged)}</p>
                    </div>

                    <div className="flex flex-col items-center gap-2">
                        <button 
                            className='bg-blue-500 hover:bg-blue-700 transition-colors text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline' 
                            onClick={() => setActivitySummaryIsVisible(false)}
                        >
                            Close
                        </button>
                    </div>
                    <div className="flex flex-col items-center gap-2">
                        
                    </div>
                </div>
            </div>
        </Modal>
    )
});

export default ActivitySummary;