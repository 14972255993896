import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStore } from '../../stores/store';
import { Formik } from 'formik';
import { EditFormMode } from '../../enums/EditFormMode';
import { EditActivity } from '../../models/Activities/EditActivity';
import Question from '../Question';
import * as Yup from 'yup';
import Modal from '../Modal';

const EditActivityFormModal = observer(() => {
    const { activityStore, userProfileStore } = useStore();
    const { 
        editActivityFormIsVisible, 
        selectedActivityToEdit, 
        editActivityFormMode, 
        handleCreateOrEditActivity,
        formSubmissionIsLoading } = activityStore;
    const { userProfile } = userProfileStore;

    interface FormValues {
        id: string | undefined,
        dateTime: string,
        startDateTime: string,
        finishDateTime: string,
        hourlyRate: number,
        description: string
    }

    const mapSelectedActivityToFormValues = (activity: EditActivity): FormValues => {
        return {
            id: activity.id,
            dateTime: activity.dateTime,
            startDateTime: activity.startDateTime,
            finishDateTime: activity.finishDateTime,
            hourlyRate: activity.hourlyRate,
            description: activity.description
        };
    }

    const mapFormValuesToActivityPayload = (formValues: FormValues): EditActivity => {
        const startDateTime = combineDateTime(formValues.dateTime, formValues.startDateTime);
        const finishDateTime = combineDateTime(formValues.dateTime, formValues.finishDateTime);

        return {
            id: formValues.id,
            dateTime: formValues.dateTime,
            startDateTime: startDateTime,
            finishDateTime: finishDateTime,
            hourlyRate: formValues.hourlyRate,
            description: formValues.description
        };
    };

    const combineDateTime = (date: string, time: string): string => {
        return `${date}T${time}:00Z`;
    }

    const userLocale = navigator.language || 'en-US'; 

    const formVals: FormValues = selectedActivityToEdit && editActivityFormMode === EditFormMode.Update 
        ? mapSelectedActivityToFormValues(selectedActivityToEdit) 
        : {
            id: '',
            dateTime: new Date().toISOString().substring(0, 10),
            startDateTime: new Date().toLocaleTimeString(userLocale, {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            }),
            finishDateTime: new Date().toLocaleTimeString(userLocale, {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
            }),
            hourlyRate: userProfile?.baseHourlyRate ?? 10,
            description: ''
        };

    const getSubmitButtonText = () => {
        if (formSubmissionIsLoading) {
            return 'Loading...';
        }
        return editActivityFormMode === EditFormMode.Create ? 'Submit' : 'Update';
    }

    if (!editActivityFormIsVisible) {
        return null;
    }

    const validationSchema = Yup.object().shape({
        dateTime: Yup.string()
            .required('Date is required'),
        startDateTime: Yup.string()
            .required('Start time is required')
            .test(
            'is-before',
            'Start time must be before finish time',
            function(value) {
                const { finishDateTime, dateTime } = this.parent;
                const start = new Date(combineDateTime(dateTime, value));
                const finish = new Date(combineDateTime(dateTime, finishDateTime));

                return start < finish;
            }),
        finishDateTime: Yup.string()
            .required('Finish time is required')
            .test(
                'is-greater',
                'Finish time must be after start time',
                function(value) {
                    const { startDateTime, dateTime } = this.parent;
                    const start = new Date(combineDateTime(dateTime, startDateTime));
                    const finish = new Date(combineDateTime(dateTime, value));
                    return finish > start;
                }),
        hourlyRate: Yup.number()
            .required('Hourly rate is required')
            .min(0.01, 'Hourly rate must be greater than 0'),
        description: Yup.string()
            .max(100, 'Description must be less than 100 characters')
    });

    return (
        <Modal>
            <h2 className="text-2xl font-bold mb-4 text-center">{editActivityFormMode === EditFormMode.Create ? 'Create' : 'Edit'} Activity</h2>
            <Formik
                initialValues={formVals}
                onSubmit={(values) => handleCreateOrEditActivity(mapFormValuesToActivityPayload(values))}
                enableReinitialize
                validationSchema={validationSchema}
            >
                {({ handleSubmit, handleChange, handleBlur, values, errors, touched, isValid }) => (
                    <form onSubmit={handleSubmit}>
                        <input type="hidden" value={values.id} />
                        <Question 
                            type='date' 
                            id="dateTime" 
                            title="Date" 
                            error={errors.dateTime} 
                            value={values.dateTime} 
                            touched={touched.dateTime} 
                            handleChangeEvent={handleChange} 
                            handleBlurEvent={handleBlur} />

                        <Question 
                            type='time' 
                            id="startDateTime" 
                            title="Start" 
                            error={errors.startDateTime} 
                            touched={touched.startDateTime} 
                            value={values.startDateTime} 
                            handleChangeEvent={handleChange} 
                            handleBlurEvent={handleBlur} />

                        <Question 
                            type='time' 
                            id="finishDateTime" 
                            title="Finish" 
                            error={errors.finishDateTime} 
                            touched={touched.finishDateTime} 
                            value={values.finishDateTime} 
                            handleChangeEvent={handleChange} 
                            handleBlurEvent={handleBlur} />
                        <Question 
                            type="text" 
                            id="description" 
                            title="Description" 
                            error={errors.description} 
                            touched={touched.description} 
                            placeHolder='Details of the work' 
                            value={values.description} 
                            handleChangeEvent={handleChange} 
                            handleBlurEvent={handleBlur} />

                        <Question 
                            type='number' 
                            id="hourlyRate" 
                            title="Hourly Rate" 
                            error={errors.hourlyRate} 
                            touched={touched.hourlyRate} 
                            value={values.hourlyRate.toString()} 
                            maxLength={10} 
                            handleChangeEvent={handleChange} 
                            handleBlurEvent={handleBlur} />

                        <br />
                        <div className="flex flex-col items-center gap-2">
                            <input 
                                id='submitButton' 
                                className='bg-green-500 hover:bg-green-700 transition-colors text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline' 
                                type="submit" 
                                disabled={formSubmissionIsLoading || !isValid} 
                                value={getSubmitButtonText()} 
                            />
                            <button 
                                className='bg-blue-500 hover:bg-blue-700 transition-colors text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline' 
                                onClick={() => activityStore.setEditActivityFormVisible(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </Modal>
    );
});

export default EditActivityFormModal;
