import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-slate-500 p-5 text-center text-white mt-auto">
            <p>&copy; 2024 TimeIo</p>
        </footer>
    );
}

export default Footer;
