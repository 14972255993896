import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { Activity } from '../../models/Activities/Activity';
import { FaPencil, FaRegTrashCan } from 'react-icons/fa6';
import { useStore } from '../../stores/store';

interface Props {
    activity: Activity;
    deleteActivity: (id: string) => void;
    openEditForm: (id: string) => void;
}

const ActivityTableRow = ({ activity, deleteActivity, openEditForm } : Props) => {
    const [isDeleting, setIsDeleting] = React.useState(false);
    const { activityStore } = useStore();
    const { setActivitySummaryIsVisible } = activityStore;

    const removeSeconds = (time : string) : string => time.substring(0, 5);

    const extractDate = (date : Date) : string => new Date(date).toDateString();

    const extractTime = (date : Date) : string => {
        let time = date.toString().split('T')[1];
        return removeSeconds(time);
    }

    const deleteActivityState = () => {
        setIsDeleting(true);
        deleteActivity(activity.id);
    }
    
    return (
        <TableRow key={activity.id} className="activityTable__row" onClick={() => setActivitySummaryIsVisible(true, activity.id)}>
            <TableCell>{extractDate(activity.startDateTime)}</TableCell>
            <TableCell>{extractTime(activity.startDateTime)}</TableCell>
            <TableCell>{extractTime(activity.finishDateTime)}</TableCell>
            <TableCell>{removeSeconds(activity.totalTime)}</TableCell>
            <TableCell>£{activity.amountCharged.toFixed(2)}</TableCell>
            <TableCell className='w-1 whitespace-nowrap'>
                <div className="flex">
                    <button className="flex text-sm p-2 m-1 bg-yellow-400 rounded" onClick={(e) => {
                        e.stopPropagation();
                        openEditForm(activity.id);
                    }}><FaPencil/></button>
                    <button className="flex text-sm p-2 m-1 bg-red-400 rounded" onClick={(e) => {
                        e.stopPropagation();
                        deleteActivityState();
                    }}>{ isDeleting ? <span>. . .</span> : <span><FaRegTrashCan /></span> }</button>
                </div>
            </TableCell>
        </TableRow>
    )
}

export default ActivityTableRow;