import axios, { AxiosError, AxiosResponse } from "axios";
import { store } from "../stores/store";
import { toast } from "react-toastify";
import { LoginDTO } from "../models/Login/LoginDTO";
import { LoginResponse } from "../models/Login/LoginResponse";
import { EditActivity } from "../models/Activities/EditActivity";
import { CreateEditActivityResponse } from "../models/Activities/CreateEditActivityResponse";
import { UserProfile } from "../models/UserProfile/UserProfile";
import { UpdateUserProfileRequestDto } from "../models/UserProfile/UpdateUserProfileRequestDto";
import { ListActivitiesResponse } from "../models/Activities/ListActivitiesResponse";
import { RegisterDto } from "../models/Login/RegisterDto";
import { RefreshDto } from "../models/Login/RefreshDto";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.authStore.token;
    if(token && config !== undefined) config!.headers!.Authorization = `Bearer ${token}`;
    return config;
});

axios.interceptors.response.use(async response => {
    return response;
    }, 
    async (error : AxiosError) => {
        if (error.response) {
            const { status, data, config } = error.response;
            switch(status){
                case 400:
                    break;
                case 401:
                    await store.authStore.refresh();
                    if (store.authStore.token) {
                        config.headers['Authorization'] = `Bearer ${store.authStore.token}`;
                        return axios(config);
                    }
                    break;
                case 404:
                    break;
                default:
                    toast.error(`Unexpected error: ${status}`);
                    break;
            }
        }

    return Promise.reject(error);
    })

const responseBody = <T> (response : AxiosResponse<T>) => response;

const requests = {
    get : <T> (url: string) => axios.get<T>(url).then(responseBody),
    post : <T> (url: string, body : {}) => axios.post<T>(url, body).then(responseBody),
    put : <T> (url: string, body : {}) => axios.put<T>(url, body).then(responseBody),
    delete : <T> (url: string) => axios.delete<T>(url).then(responseBody),
}

const activities = {
    list : (monthYear : string) => requests.get<ListActivitiesResponse>(`/activities?monthYear=${monthYear}`),
    create : (activity : EditActivity) => requests.post<CreateEditActivityResponse>('/activities', activity),
    edit : (activity : EditActivity) => requests.put<CreateEditActivityResponse>(`/activities/${activity.id}`, activity),
    delete: (id : string, startDate : string) => requests.delete<string>(`/activities/${id}?monthYear=${startDate}`),
}

const authentication = {
    login : (credentials : LoginDTO) => requests.post<LoginResponse>('/login', credentials),
    register : (credentials : RegisterDto) => requests.post<string>('/register', credentials),
    refresh: (credentials : RefreshDto) => requests.post<LoginResponse>('/login/refresh', credentials)
}   

const userProfile = {
    get : () => requests.get<UserProfile>('/userprofile'),
    update: (userProfile : UpdateUserProfileRequestDto) => requests.put<UserProfile>('/userprofile', userProfile)
}

const exportItems = {
    activities : (monthYear : string) => requests.get<string>(`/export/activities?monthYear=${monthYear}`)
}

const agent = {
    activities,
    authentication,
    userProfile,
    exportItems
};

export default agent